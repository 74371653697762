import getCurrency from "helpers/get-currency";
import { initializeAppState, updateRecentlyViewed } from "actions/settings";

const initialState = {
	isMenuOpen: false,
	isSearchOpen: false,
	isCartOpen: false,
	customerCountry: "",
	currencyCode: "",
	productSettings: {
		/*
		[id]: {
			isCylindoFirstSlide: false,
			activeTab: 0,
			...otherSettings
		}
		 */
	},
	pageLoading: true,
	recentlyViewed: [],
	isClient: false,
};

const settingsSlice = (set, get) => ({
	...initialState,

	initializeAppState: () => initializeAppState(get),

	setIsClient: () => {
		const { isClient } = get().settings;
		if (isClient) return;
		set(state => {
			state.settings.isClient = true;
		}, false, "SET_IS_CLIENT");
	},

	togglePageLoading: () => {
		set(state => {
			state.settings.pageLoading = false;
		}, false, "UPDATE_PAGE_LOADING");
	},

	updateCustomerLocation: () => {
		const { countryCode: customerCountryCode, code: customerCurrencyCode } = getCurrency();
		set(state => {
			state.settings.currencyCode = customerCurrencyCode;
			state.settings.customerCountry = customerCountryCode;
		}, false, "UPDATE_CUSTOMER_LOCALE");
	},

	updateProductSettings: (productId, settings) => {
		set(state => {
			state.settings.productSettings = {
				...state.settings.productSettings,
				[productId]: {
					...state.settings.productSettings?.[productId],
					...settings,
				},
			};
		}, false, "UPDATE_PRODUCT_SETTINGS");
	},

	updateCylindoState: (productId, visibility) => {
		get().settings.updateProductSettings(productId, { isCylindoFirstSlide: visibility });
	},

	updateActiveTab: (productId, activeTab) => {
		get().settings.updateProductSettings(productId, { activeTab });
	},

	updateRecentlyViewed: async (product = null) => updateRecentlyViewed(set, get, product),

	toggleMenu: (value) => {
		set(state => {
			state.settings.isMenuOpen = typeof value === "boolean" ? value : !state.settings.isMenuOpen;
			state.settings.isSearchOpen = false;
			state.settings.isCartOpen = false;
		}, false, "TOGGLE_MENU");
	},

	toggleSearch: () => {
		set(state => {
			state.settings.isSearchOpen = !state.settings.isSearchOpen;
			state.settings.isMenuOpen = false;
			state.settings.isCartOpen = false;
		}, false, "TOGGLE_SEARCH");
	},

	toggleCart: () => {
		set(state => {
			state.settings.isCartOpen = !state.settings.isCartOpen;
			state.settings.isMenuOpen = false;
			state.settings.isSearchOpen = false;
		}, false, "TOGGLE_CART");
	},
});

export default settingsSlice;