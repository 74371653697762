import { trackUserData } from "../analytics";
import { recentlyViewedKey } from "utils/constants";
import { fetchProductsByIds } from "services/api/rebuy";
import { mapRebuyProduct } from "helpers/rebuy";
import { removeTabIndexAttribute } from "utils/gatbsy";

const fetchCustomerAndCart = (customer, cart) => {
	const customerPromise = customer?.id ? Promise.resolve(customer) : customer.fetchCustomerProfile();
	const cartPromise = cart.id ? Promise.resolve({ items: cart.items, totalPrice: cart.totalPrice }) : cart.fetchCart();

	Promise.all([customerPromise, cartPromise])
		.then(([customerData, cartData]) => trackUserData(customerData, cartData))
		.catch((error) => {
			console.error("Error fetching customer and cart data:", error);
		});
};

export const initializeAppState = async (get) => {
	try {
		const { settings, wishlist, customer, cart } = get();

		// Initialize settings
		settings.togglePageLoading();
		settings.setIsClient();
		settings.updateCustomerLocation();

		// Initialize wishlist
		wishlist.updateListContents();

		// Fetch customer and cart data
		fetchCustomerAndCart(customer, cart);

		// Remove tabindex attribute
		removeTabIndexAttribute();

	} catch (error) {
		console.error("Error initializing app:", error);
	}
};

export const updateRecentlyViewed = async (set, get, product = null) => {
	const recentIds = JSON.parse(localStorage.getItem(recentlyViewedKey)) || [];

	// Check if local storage is not empty and state has no items
	if (recentIds.length > 0 && get().settings.recentlyViewed.length === 0) {
		try {
			const products = await fetchProductsByIds(recentIds);

			// Sort products to match the order in recently viewed titles
			const sortedProducts = recentIds
				.map(id => {
					const product = products?.find(product => +product.id === +id);
					return product ? mapRebuyProduct(product) : false;
				})
				.filter(Boolean);

			set(state => {
				state.settings.recentlyViewed = sortedProducts;
			}, false, "FETCH_RECENTLY_VIEWED");

		} catch (error) {
			console.error("Error fetching products:", error);
		}
	}

	set(state => {
		const recentlyViewed = get().settings.recentlyViewed;

		const uniqueProducts = Array.from(
			new Map([product, ...recentlyViewed].map(item => [+item.id, item])).values()
		).slice(0, 10);

		state.settings.recentlyViewed = uniqueProducts;
		localStorage.setItem(recentlyViewedKey, JSON.stringify(uniqueProducts.map(p => +p.id)));
	}, false, "UPDATE_RECENTLY_VIEWED");

};
